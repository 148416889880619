<script setup lang="ts">
</script>

<template>
  <NuxtLoadingIndicator />
  <div
    data-layout="tools-and-services/market-maker-monitoring"
    class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
  >
    <div class="flex min-h-screen relative">
      <AppMenuExpandable :expanded="false" class="z-5" />
      <div class="flex flex-col flex-grow">
        <div
          class="h-[80px] px-5 flex justify-between items-center border-b border-forgd-bgd-600"
        >
          <div class="flex gap-4 items-center">
            <img src="/app/illustrations/token-designer-alt.svg" alt="Token Designer Icon" width="32" height="32">
            <div class="text-xl font-semibold flex-shrink-0">
              Market Maker Monitoring
            </div>
          </div>
          <UiUserProjectsDropDown />
        </div>
        <div class="flex flex-grow">
          <slot />
        </div>
        <AppFooter />
      </div>
    </div>
  </div>

  <UNotifications />

  <USlideovers />
</template>
